import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import BlogCard from '@components/BlogCard';
import { graphql, Link } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';

const Blog = ({
    data: {
        blogs: { edges: blogs },
        page: {
            frontmatter: { title, description },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`Blogs`} />
            <div className={styles.blogWrapper}>
                <Header title={title} description={description} />
                <div className={styles.blogContent}>
                    {blogs
                        ?.map(({ node: { frontmatter: blog } }) => blog)
                        ?.sort((a, b) => a?.date - b?.date ? 1 : -1)
                        ?.map((blog, index) => (
                        <BlogCard
                            title={blog?.title}
                            description={blog?.description}
                            path={`/blog/${blog?.slug}`}
                            image={blog?.image}
                            key={index}
                        />
                    ))}
                </div>
            </div>

            <Divider />
            <Divider />
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query blogPage($path: String!) {
        blogs: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/blog/" } }) {
            edges {
                node {
                    frontmatter {
                        date: blogs_blog_date
                        description: blogs_blog_overview_description
                        title: blogs_blog_title
                        image: blogs_blog_overview_image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        slug
                    }
                }
            }
        }
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: blog_title
                description: blog_description
            }
        }
    }
`;
