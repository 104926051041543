import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const BlogCard = ({ title, description, path, image }) => {
    return (
        <div className={styles.blogCard}>
            <Link to={path}>
                <Grid>
                    <Row>
                        <Col lg={6} xs={12}>
                            <div className={styles.blogCardImage}>
                                <Img fluid={image?.childImageSharp?.fluid} alt={title} />
                            </div>
                        </Col>
                        <Col lg={6} xs={12}>
                            <div className={styles.blogCardContent}>
                                <div className={styles.heading}>
                                    <div className={styles.headingText}>
                                        <h3>{title}</h3>
                                        <p className={styles.hint}>Tap to read</p>
                                    </div>
                                    <img src="/images/squiggle.svg" alt="a squiggle line" />
                                </div>
                                <div className={styles.description}>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </Link>
        </div>
    );
};

export default BlogCard;
